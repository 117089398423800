/**
 * DocPage template that...
 *
 */
import * as React from 'react'
// utilities
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/layout'
import Toc from '../components/toc'

const DocPage = ({ data }) => {
  const { frontmatter, body, tableOfContents } = data.mdx
  //console.log(data)
  return (
    <Layout>
      <main className="l-main">
        <div className="l-constrained">
          <div className="l-grid-sb1">
            <aside className="sidebar">
              {frontmatter.toc && tableOfContents?.items && (
                <Toc {...data.mdx} />
              )}
            </aside>
            <article className="main">
              <h1 id="page-title" className="page-title">
                {frontmatter.title}
              </h1>
              <MDXRenderer>{body}</MDXRenderer>
            </article>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query docByIdQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      excerpt
      body
      tableOfContents
      frontmatter {
        title
        description
        link
        toc
      }
      fields {
        slug
      }
    }
  }
`
export default DocPage
