/**
 * icons utility that...
 *
 */
 import {
  faFacebook,
  faGithubAlt,
  faLinkedin,
  faMastodon,
  faTwitter,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import { 
  faCalendarAlt, 
  faClock,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons';
import { 
  faEnvelope,
  faFileAlt,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';

export const getFaIcon = (name) => {
  switch(name?.toLowerCase()) {
    // brands
    case 'facebook':
      return faFacebook
    case 'github':
      return faGithubAlt
    case 'linkedin':
      return faLinkedin
    case 'mastodon':
      return faMastodon
    case 'twitter':
      return faTwitter
    case 'website':
      return faGlobe
    case 'whatsapp':
      return faWhatsapp
    // regular
    case 'calendar':
      return faCalendarAlt
    case 'clock':
      return faClock
    case 'user':
      return faUserCircle
    case 'file':
      return faFileAlt
    // solid
    case 'email':
      return faEnvelope
    default:
      return
  }
}