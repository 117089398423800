/**
 * Toc component that...
 *
 */
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFaIcon } from '../../utils/icons'
import * as tocStyles from '../styles/toc.module.scss'

export default function Toc(props: TocProps) {
  return (
    <nav className={tocStyles.toc} aria-label="Table of contents">
      <details>
        <summary>
          <FontAwesomeIcon
            icon={getFaIcon(`file`)}
            size="1x"
            aria-hidden="true"
          />
          {` `}Table of Contents
        </summary>
        {renderItems(props.tableOfContents.items)}
      </details>
    </nav>
  )
}
interface TocProps {
  tableOfContents: []
}

const renderItems = (items) => {
  return (
    <ul>
      {items.map((item) => (
        <li key={item.url}>
          <a href={item.url}>{item.title}</a>
          {item.items && renderItems(item.items)}
        </li>
      ))}
    </ul>
  )
}
